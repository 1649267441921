import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import * as Icons from '@ant-design/icons-vue';
import Antd, {notification} from 'ant-design-vue';
import './assets/js/session-storage'
import axios from "axios";

const app = createApp(App);
app.use(Antd).use(store).use(router).mount('#app');

// 全局使用图标
const icons = Icons;
for (const i in icons) {
    app.component(i, icons[i]);
}


axios.interceptors.request.use(function (config) {
    console.log("请求参数", config);
    let token = store.state.member.token;
    if (token) {
        config.headers.Authorization = "Bearer "+token;
        console.log("请求header增加token:", token);
    }
    return config;
},error => {
    return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    console.log("返回结果", response);
    return response;
},error => {
    console.log("返回错误", error);

    let response = error.response;
    let status = response.status;
    if (status===401||status===500){
        console.log("未登录或登陆超时");
        store.commit("setMember", {});
        notification.error({description:"未登录或登陆超时"})
        router.push("/login")
    }

    return Promise.reject(error);
});

axios.defaults.baseURL = process.env.VUE_APP_SERVER;
console.log("环境:", process.env.NODE_ENV);
console.log("环境:", process.env.VUE_APP_SERVER);